import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { colors, radius } from '../../utils/styles'

export const ButtonBase = styled(`button`)`
  align-items: center;
  background: ${colors.lightest};
  border: 1px solid ${colors.brand};
  border-radius: ${radius.default}px;
  color: ${colors.brand};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 0.75em 0.75rem;
  transition: 0.3s;
  outline: none;

  font-family: 'Merriweather';
  font-weight: bold;
  font-size: 1rem;

  svg {
    height: 1.1em;
    margin-left: ${(props) => (props.iconOnLeft ? 0 : '0.5rem')};
    margin-right: ${(props) => (props.iconOnLeft ? '0.5rem' : 0)};
    width: 1.1em;
  }

  &.error {
    background: ${colors.red};
    &:hover {
      background: ${colors.red};
    }
  }

  @media (hover: hover) {
    &:hover {
      color: ${colors.lightest};
      background: ${colors.brand};
    }
  }
`

const ButtonAsExternalLink = styled(ButtonBase.withComponent(`a`))`
  display: inline-flex;
  text-decoration: none;
`

const ButtonAsInternalLink = ButtonAsExternalLink.withComponent(
  ({ iconOnLeft, ...rest }) => <Link {...rest} />
)

export class Button extends Component {
  render() {
    const { children, to, href, ref, ...rest } = this.props

    // automtic recognition of icon placement, works properly only for [text + <Icon>] childrens
    const iconOnLeft = typeof children[0] !== 'string'

    if (to) {
      return (
        <ButtonAsInternalLink to={to} iconOnLeft={iconOnLeft} {...rest}>
          {children}
        </ButtonAsInternalLink>
      )
    } else if (href) {
      return (
        <ButtonAsExternalLink href={href} iconOnLeft={iconOnLeft} {...rest}>
          {children}
        </ButtonAsExternalLink>
      )
    } else {
      return (
        <ButtonBase iconOnLeft={iconOnLeft} {...rest}>
          {children}
        </ButtonBase>
      )
    }
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
}

export const PrimaryButton = styled(Button)`
  background: ${colors.brand};
  color: ${colors.lightest};
  display: flex;
  justify-content: center;
  border: none;
  @media (hover: hover) {
    &:hover {
      background: ${colors.brandDark};
      color: ${colors.lightest};
    }
  }
`
