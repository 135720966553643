import React, { useState } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import classNames from 'classnames'
import { Parser as HtmlToReactParser } from 'html-to-react'
const htmlToReactParser = new HtmlToReactParser()

import FadeInItem from '../shared/FadeInItem'

import { breakpoints, colors, spacing, dimensions } from '../../utils/styles'

const TRANSITION_DURATION = '250ms'

const SearchListingItemLink = styled(Link)`
  background: ${colors.lightest};
  margin-bottom: ${spacing.lg}px;

  text-decoration: none;
  transition: all ${TRANSITION_DURATION};

  @media (max-width: ${breakpoints.phablet}px) {
    margin-top: ${spacing['2xl']}px;
  }

  @media (min-width: ${breakpoints.phablet}px) and (max-width: ${breakpoints.desktop}px) {
    flex-basis: calc(50% - 2 * ${spacing.md}px);
    justify-content: center;
    margin: ${spacing.md}px;
    margin-top: ${spacing['2xl']}px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    flex-basis: calc(33% - 3 * ${spacing.md}px);
    justify-content: center;
    margin: ${spacing.lg}px;
    margin-top: ${spacing.lg}px;
  }
`

const SearchListingItem = (props) => {
  const {
    product: { title, handle, descriptionHtml, variants, tags, ...otherProps },
  } = props

  const firstVariant = variants[0]
  const [currentVariant, setCurrentVariant] = useState(firstVariant)

  const fluid = get(currentVariant, 'image.localFile.childImageSharp.fluid')

  return (
    <SearchListingItemLink to={`/products/${handle}`} {...otherProps}>
      <FadeInItem>
        <article className="item">
          <div className="flex-parent flex-parent--column previewContainer">
            <div className="preview">
              {fluid ? (
                <Image fluid={fluid} />
              ) : (
                <div
                  style={{
                    width: '100%',
                    paddingBottom: '133.333%',
                    backgroundColor: colors.ligherGrey,
                  }}
                />
              )}
            </div>
          </div>

          <div className="productInfo flex-parent flex-parent--row flex-parent--space-between-main">
            <div>
              <h5 style={{ fontWeight: '600', fontSize: '20px' }}>{title}</h5>
              <div className="description">
                {htmlToReactParser.parse(descriptionHtml)}
              </div>
            </div>
          </div>
        </article>
      </FadeInItem>
      <style jsx>
        {`
        .item {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .previewContainer {
          position: relative;
        }
        .productInfo {
          padding-top: ${spacing.md}px;
          max-height: 150px;
          overflow: hidden;
        }
        .description {
          marginTop: ${spacing['2xs']}px;
        `}
      </style>
    </SearchListingItemLink>
  )
}

SearchListingItem.propTypes = {
  product: PropTypes.object.isRequired,
}

export default SearchListingItem
