import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Link } from 'gatsby'

import { colors } from '../../utils/styles'

const AnchorBase = styled(`a`)`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  align-items: center;
  color: ${colors.darkest};
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  outline: none;

  font-family: 'Merriweather';
  font-weight: normal;
  font-size: 1rem;
  width: fit-content;

  svg {
    height: 1.1em;
    margin-left: ${(props) => (props.iconOnRight ? '0.5rem' : 0)};
    margin-right: ${(props) => (props.iconOnRight ? 0 : '0.5rem')};
    width: 1.1em;
  }

  :after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    width: 0;
    bottom: -2px;
    background: ${colors.brand};
    height: 1px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  :hover:after,
  :focus:after,
  :active:after {
    left: 0;
    right: auto;
    width: 100%;
  }
`

const AnchorAsExternalLink = styled(AnchorBase.withComponent(`a`))`
  display: inline-flex;
  text-decoration: none;
`

const AnchorAsInternalLink = AnchorAsExternalLink.withComponent(
  ({ iconOnRight, ...rest }) => <Link {...rest} />
)

export class Anchor extends Component {
  render() {
    const { children, to, href, ref, target, ...rest } = this.props

    // automtic recognition of icon placement, works properly only for [text + <Icon>] childrens
    const iconOnRight = children.length > 0 && typeof children[1] !== 'string'

    if (to) {
      return (
        <AnchorAsInternalLink
          to={to}
          iconOnRight={iconOnRight}
          target={target}
          {...rest}
        >
          {children}
        </AnchorAsInternalLink>
      )
    } else if (href) {
      return (
        <AnchorAsExternalLink
          href={href}
          iconOnRight={iconOnRight}
          target={target}
          {...rest}
        >
          {children}
        </AnchorAsExternalLink>
      )
    } else {
      return (
        <AnchorBase iconOnRight={iconOnRight} target={target} {...rest}>
          {children}
        </AnchorBase>
      )
    }
  }
}

Anchor.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
}

export const PrimaryAnchor = styled(Anchor)`
  color: ${colors.brand};
  display: inline-flex;
  justify-content: center;
`
