import React, { Component } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import PropTypes from 'prop-types'

import {
  MdClose,
  MdShoppingSearch,
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md'

import StoreContext from '../../contexts/StoreContext'

import { Button, PrimaryButton } from './Buttons'
import { Anchor } from './Anchors'
import SearchListing from '../Search/SearchListing'
import { breakpoints, colors, spacing, dimensions } from '../../utils/styles'

const SideMenuRoot = styled(`div`)`
  background: ${colors.lightest};
  bottom: 0;
  position: fixed;
  left: 0;
  top: 0px;
  transition: all 0.25s;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  padding: ${spacing['md']}px;
  overflow: auto;

  &.open {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    padding: ${spacing['lg']}px;
  }
`

class Search extends Component {
  state = {
    className: 'closed',
  }

  componentDidUpdate(prevProps) {
    const componentStatusChanged = prevProps.status !== this.props.status

    if (componentStatusChanged) {
      this.setState({
        className: this.props.status,
      })
    }
  }

  render() {
    const { status, toggle, links } = this.props
    const { className } = this.state

    return (
      <SideMenuRoot className={`${className}`}>
        <ul className="flex-parent flex-parent--column list topList">
          <li>
            <Anchor to={'/collections/plants'}>
              <h3>Plante</h3>
            </Anchor>
          </li>
          <li>
            <Anchor to={'/collections/pots'}>
              <h3>Ghivece</h3>
            </Anchor>
          </li>
          {/* <li>
            <Anchor to={'/collections/accesories'}>
              <h3>Accesorii</h3>
            </Anchor>
          </li> */}
        </ul>
        <div className="separator" />
        <ul className="flex-parent flex-parent--column list">
          {links.map(link => (
            <li key={link.to}>
              <Anchor to={link.to}>
                <p>{link.text}</p>
              </Anchor>
            </li>
          ))}
        </ul>
        <style jsx>{`
          .topList {
            padding-top: 120px !important;
          }
          .list {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          .list li {
            margin-bottom: ${spacing.md}px;
            display: flex;
          }
          .list > li:last-of-type {
            margin-bottom: 0px;
          }
          .separator {
            margin-bottom: ${spacing.xl}px;
            margin-top: ${spacing.xl}px;
            height: 1px;
            width: 100%;
            background-color: ${colors.ligherGrey};
          }
        `}</style>
      </SideMenuRoot>
    )
  }
}

Search.propTypes = {
  status: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default Search
