import React, { useState } from 'react'
import qs from 'qs'
import classNames from 'classnames'

import { breakpoints, colors, spacing } from '../../utils/styles'

const SubscriberForm = () => {
  const [state, setState] = useState({
    success: null,
    isLoading: false,
  })

  const [formState, setFormState] = useState({
    email: '',
    emailHoney: '',
  })

  const submitForm = async (e) => {
    e.preventDefault()

    if (formState.emailHoney.length > 0) {
      //SPAM
      return
    }

    setState({
      loading: true,
    })
    try {
      const response = await fetch('/api/subscribeEmail', {
        method: 'POST',
        body: qs.stringify(formState),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      })

      if (!response.ok) {
        //not 200 response
        setState({ loading: false, success: false })
        return
      }

      //all OK
      setState({ loading: false, success: true })
    } catch (e) {
      setState({ loading: false, success: false })
      //error
    }
  }

  const submitString = () => {
    if (state.success === true) return 'Abonare cu succes'
    if (state.success === false) return 'Abonare esuata'
    return 'Aboneaza-te'
  }
  return (
    <div id="mc_embed_signup">
      <form
        onSubmit={submitForm}
        method="post"
        id="mc-embedded-subscribe-form"
        className="validate"
      >
        <div id="mc_embed_signup_scroll">
          <label>Sfaturi de plante in casuta ta de email.</label>
          <div className="mc-field-group">
            <input
              type="email"
              value={formState.email}
              name="email"
              placeholder="Email"
              className="required email"
              id="mce-EMAIL"
              onChange={(e) =>
                setFormState({ ...formState, [e.target.name]: e.target.value })
              }
            />
            <input
              type="email"
              value={formState.emailHoney}
              name="emailHoney"
              placeholder="Email"
              className="ohnohoney"
              id="emailHoney"
              onChange={(e) =>
                setFormState({ ...formState, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="clear">
            <input
              type="submit"
              value={submitString()}
              name="subscribe"
              id="mc-embedded-subscribe"
              className={classNames('button', {
                response_success: state.success === true,
                response_error: state.success === false,
              })}
              disabled={state.success === true}
            />
          </div>
        </div>
      </form>
      <style jsx>{`
        #mc-embedded-subscribe-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: 'Merriweather';
        }
        #mc-embedded-subscribe-form > * {
          font-family: 'Merriweather';
        }
        #mc_embed_signup_scroll {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        #mc-embedded-subscribe-form > div,
        #mce-EMAIL,
        #mc-embedded-subscribe {
          width: 100%;
        }
        #mc-embedded-subscribe-form label {
          margin-bottom: ${spacing.md}px;
          text-align: center;
        }
        #mce-EMAIL {
          height: 40px;
          min-height: 40px;
          margin: 0;
          padding: 0;
          border: 1px solid ${colors.lightGrey};
          border-bottom: 0;
          text-align: center;
          font-size: 14px;
        }
        #mc-embedded-subscribe {
          height: 40px;
          min-height: 40px;
          margin: 0;
          padding: 0;
          border: 1px solid ${colors.lightGrey};
          font-size: 14px;
          cursor: pointer;
          background-color: white;
        }
        .mce-responses {
          margin-top: 16px;
          display: flex;
          justify-content: center;
        }
        .response_success {
          color: ${colors.brand};
        }
        .response_error {
          color: ${colors.red};
        }
      `}</style>
    </div>
  )
}

export default SubscriberForm
