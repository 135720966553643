import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SearchListingItem from './SearchListingItem'
import { breakpoints, colors, spacing, dimensions } from '../../utils/styles'

function filterByValue(array, string) {
  return array
    .map((el) => el.node)
    .filter((o) =>
      Object.keys(o).some((k) => {
        if (typeof o[k] === 'string') {
          return o[k].toLowerCase().includes(string.toLowerCase())
        }
        return null
      })
    )
}

const SearchListing = ({ allShopifyProduct, searchInput }) => {
  let results = []
  if (
    searchInput &&
    searchInput.length > 0 &&
    allShopifyProduct &&
    allShopifyProduct.edges.length > 0
  ) {
    //filter
    results = filterByValue(allShopifyProduct.edges, searchInput)
  }

  return (
    <>
      {results.length > 0 ? (
        <div className="productContainer">
          {results.map((product) => {
            return <SearchListingItem key={product.id} product={product} />
          })}
        </div>
      ) : (
        <div className="noResults">
          <h4>Niciun rezultat.</h4>
          <p>Incearca o alta cautare.</p>
        </div>
      )}
      <style jsx>{`
        .productContainer {
          display: flex;
          flex-direction: column;
          padding-top: ${spacing.xs}px;
        }
        .noResults {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .noResults > :not(:last-child) {
          margin-bottom: ${spacing.xs}px;
        }
        .collectionSort {
          border: 0 !important;
          padding-right: 0;
          background-position: right center;
          margin-top: 0;
          padding-bottom: 0;

          appearance: none;
          background-color: transparent;
          background-size: 12px 8px;
          background-position: right 10px center;
          background-repeat: no-repeat;
          background-image: url(/images/down-black.svg);
          border-radius: 1px;
          cursor: pointer;
          height: 32px;
          line-height: 32px;
          margin: 0;
          position: relative;
          outline: 0;
          padding-left: ${spacing.sm}px;
          text-align: center;
          width: 100%;
          flex-basis: 160px;
        }
        @media (min-width: ${breakpoints.phablet}px) {
          .productContainer {
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 0px;
            padding-left: 0px;
          }
        }
      `}</style>
    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query SearchPage {
        allShopifyProduct {
          edges {
            node {
              id
              title
              descriptionHtml
              handle
              productType
              vendor
              variants {
                id
                title
                price
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 960, maxHeight: 1280) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return <SearchListing {...data} {...props} />
    }}
  />
)
