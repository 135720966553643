import React from 'react'

export const defaultInterfaceContext = {
  isDesktopViewport: null,
  cartStatus: 'initial',
  toggleCart: () => {},
  searchStatus: 'initial',
  toggleSearch: () => {},
  sideMenuStatus: 'initial',
  toggleSideMenu: () => {},
  productImageFeatured: null,
  featureProductImage: () => {},
}

export default React.createContext(defaultInterfaceContext)
