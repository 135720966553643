import React, { Component } from 'react'

const FadeInItem = props => {
  const [isVisible, setVisible] = React.useState(false)
  const domRef = React.useRef()

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisible(entry.isIntersecting)
      })
    })

    const { current } = domRef
    observer.observe(current)

    return () => observer.unobserve(current)
  }, [])
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
      <style jsx>{`
        .fade-in-section {
          opacity: 0;
          transform: translateY(0vh);
          visibility: hidden;
          transition: opacity 600ms ease-out, transform 400ms ease-out,
            visibility 600ms ease-out;
          will-change: opacity, transform, visibility;
        }
        .fade-in-section.is-visible {
          opacity: 1;
          transform: none;
          visibility: visible;
        }
      `}</style>
    </div>
  )
}

export default FadeInItem
