import React from 'react'
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md'

import Link from '../Link'
import { Anchor } from './Anchors'
import SubscribeForm from './SubscribeForm'

import { breakpoints, colors, spacing } from '../../utils/styles'

const FooterTemplate = ({ data }) => {
  const { socialLinks, rightLinks } = data

  return (
    <nav className="footer">
      <div className="footer-container container flex-parent flex-parent--center-main firstRow">
        <div className="flex-parent flex-parent--row flex-parent--space-between-main container-links">
          <div className="flex-parent flex-parent--column links-container column1">
            {rightLinks.map((link) => (
              <Anchor
                key={link.to}
                to={link.to}
                style={{ marginBottom: `${spacing.xs}px` }}
              >
                {link.text}
              </Anchor>
            ))}
          </div>

          <div className="flex-parent flex-parent--column column3">
            <div
              className="flex-parent flex-parent--row"
              style={{ marginBottom: `${spacing.xs}px` }}
            >
              <MdLocationOn
                style={{
                  marginRight: `${spacing.xs}px`,
                  marginTop: `4px`,
                }}
              />
              <div className="flex-parent flex-parent--column contactData">
                <span>LEAF CERAMICS</span>
                <span>Str. Rozmarinului 8</span>
                <span>Cluj-Napoca, 400481</span>
              </div>
            </div>
            {/* <span
              className="flex-parent flex-parent--row flex-parent--center-cross"
              style={{ marginBottom: `${spacing.xs}px` }}
            >
              <MdPhone style={{ marginRight: `${spacing.xs}px` }} />
              <span>0743 096 406</span>
            </span> */}
            <span className="flex-parent flex-parent--row flex-parent--center-cross">
              <MdEmail style={{ marginRight: `${spacing.xs}px` }} />
              <a href="mailto:hello@leaf.ro">hello@leaf.ro</a>
            </span>
          </div>
          <div className="column2">
            <SubscribeForm />
          </div>
        </div>
      </div>

      <div className="flex-parent flex-parent--column flex-parent--center-cross">
        <div className="flex-parent flex-parent--row footer-socialMenu">
          {socialLinks.map((socialLink) => (
            <Link
              key={socialLink.id}
              to={socialLink.href}
              className="footer-socialMenuItem"
            >
              <img
                className="footer-socialLinkIcon"
                src={socialLink.image.file.url}
                alt={socialLink.text}
              />
            </Link>
          ))}
        </div>

        <p>© Leaf Ceramics SRL {new Date().getFullYear()}</p>
        <div
          className="paymentOptions flex-parent flex-parent--row"
          style={{
            marginTop: '16px',
          }}
        >
          <img
            src="/images/visa.svg"
            alt="Plati securizate de VISA"
            style={{
              display: 'block',
              width: '40px',
              height: '30px',
              outline: 'none',
              border: 'none',
              marginRight: `${spacing.xs}px`,
            }}
          />
          <img
            src="/images/mastercard.svg"
            alt="Plati securizate de Mastercard"
            style={{
              display: 'block',
              width: '40px',
              height: '30px',
              outline: 'none',
              border: 'none',
            }}
          />
        </div>
      </div>

      <style jsx>{`
        .firstRow {
          justify-content: space-between;
          width: 100%;
        }
        .footer {
          position: relative;
          margin-top: ${2 * spacing.xl}px;
          margin-bottom: ${2 * spacing.xl}px;
          width: 100%;
        }
        .container-links {
          width: 100%;
          margin-left: ${2 * spacing.xl}px;
          margin-right: ${2 * spacing.xl}px;
        }
        .footer-container {
          padding: ${2 * spacing.xl}px;
        }
        .footer::before {
          position: absolute;
          content: '';
          top: 0;
          background-color: ${colors.lightGrey};
          height: 1px;
          width: 100%;
        }
        .footer-socialMenu {
          margin-bottom: ${spacing.md}px;
        }
        :global(.footer-socialMenuItem) {
          height: ${spacing.xl}px;
          margin-right: ${spacing.xs}px;
        }
        .footer-socialLinkIcon {
          width: 100%;
          height: 100%;
        }
        :global(.footer-socialMenuItem:hover) {
          opacity: 0.65;
        }
        .footer-socialMenu :global(.footer-socialMenuItem):last-of-type {
          margin: 0;
        }
        .links-container {
        }
        .contactData {
          font-family: 'Merriweather';
          font-weight: normal;
          font-size: 1rem;
        }
        .column2 {
          width: 360px;
        }
        @media (max-width: ${breakpoints.desktop}px) {
          .container-links {
            flex-direction: column;

            margin-left: unset;
            margin-right: unset;
          }
          .links-container {
            margin-bottom: ${spacing.md}px;
            justify-content: flex-start;
          }
          .footer-container {
            padding: ${spacing.md}px;
            padding-top: ${2 * spacing.md}px;
          }
          .column1 {
            order: 3;
            display: none;
          }
          .column2 {
            order: 1;
            width: 100%;
          }
          .column3 {
            order: 2;
            margin-top: ${2 * spacing.md}px;
          }
        }
        @media (max-width: ${breakpoints.phablet}px) {
          .contact {
            width: 100%;
          }
        }
      `}</style>
    </nav>
  )
}

const Footer = (props) => {
  if (!props.data) {
    return null
  }
  return <FooterTemplate data={props.data} />
}

export default Footer
