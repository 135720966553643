import React from 'react'
import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'

import StoreContext, { defaultStoreContext } from '../contexts/StoreContext'
import InterfaceContext, {
  defaultInterfaceContext,
} from '../contexts/InterfaceContext'

import { breakpoints, dimensions } from '../utils/styles'

import Footer from './shared/Footer'
import { Header } from './shared/Header'
import Cart from './Cart'
import CartToggle from './Cart/CartToggle'
import Search from './Search'
import SideMenu from './shared/SideMenu'
import Cookie from './Cookie'

class Layout extends React.Component {
  desktopMediaQuery

  state = {
    interface: {
      ...defaultInterfaceContext,
      toggleCart: () => {
        this.setState((state) => ({
          interface: {
            ...state.interface,
            cartStatus:
              this.state.interface.cartStatus === 'open' ? 'closed' : 'open',
          },
        }))
      },
      toggleSearch: () => {
        this.setState(
          {
            interface: {
              ...this.state.interface,
              searchStatus:
                this.state.interface.searchStatus === 'open'
                  ? 'closed'
                  : 'open',
            },
          },
          () => {
            if (this.state.interface.searchStatus === 'open') {
              document.body.style.overflow = 'hidden'
            } else {
              document.body.style.overflow = 'auto'
            }
          }
        )
      },
      toggleSideMenu: () => {
        this.setState(
          {
            interface: {
              ...this.state.interface,
              sideMenuStatus:
                this.state.interface.sideMenuStatus === 'open'
                  ? 'closed'
                  : 'open',
            },
          },
          () => {
            if (this.state.interface.sideMenuStatus === 'open') {
              document.body.style.overflow = 'hidden'
            } else {
              document.body.style.overflow = 'auto'
            }
          }
        )
      },
      featureProductImage: (img) => {
        this.setState((state) => ({
          interface: {
            ...state.interface,
            productImageFeatured: img,
          },
        }))
      },
    },
    store: {
      ...defaultStoreContext,
      addVariantToCart: (variantId, quantity) => {
        if (variantId === '' || !quantity) {
          console.error('Both a size and quantity are required.')
          return
        }

        this.setState((state) => ({
          store: {
            ...state.store,
            adding: true,
          },
        }))

        const { checkout, client } = this.state.store
        const checkoutId = checkout.id
        const lineItemsToUpdate = [
          { variantId, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .addLineItems(checkoutId, lineItemsToUpdate)
          .then((checkout) => {
            this.setState((prevState) => ({
              store: {
                ...prevState.store,
                checkout,
                adding: false,
              },
            }))
          })
      },
      removeLineItem: (client, checkoutID, lineItemID) => {
        return client.checkout
          .removeLineItems(checkoutID, [lineItemID])
          .then((res) => {
            this.setState((state) => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
      updateLineItem: (client, checkoutID, lineItemID, quantity) => {
        const lineItemsToUpdate = [
          { id: lineItemID, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .updateLineItems(checkoutID, lineItemsToUpdate)
          .then((res) => {
            this.setState((state) => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
    },
  }

  async initializeCheckout() {
    // Check for an existing cart.
    const isBrowser = typeof window !== 'undefined'
    const existingCheckoutID = isBrowser
      ? localStorage.getItem('shopify_checkout_id')
      : null

    const setCheckoutInState = (checkout) => {
      if (isBrowser) {
        localStorage.setItem('shopify_checkout_id', checkout.id)
      }

      this.setState((state) => ({
        store: {
          ...state.store,
          checkout,
        },
      }))
    }

    const createNewCheckout = () => this.state.store.client.checkout.create()
    const fetchCheckout = (id) => this.state.store.client.checkout.fetch(id)

    if (existingCheckoutID) {
      try {
        const checkout = await fetchCheckout(existingCheckoutID)

        // Make sure this cart hasn’t already been purchased.
        if (!checkout.completedAt) {
          setCheckoutInState(checkout)
          return
        }
      } catch (e) {
        localStorage.setItem('shopify_checkout_id', null)
      }
    }

    const newCheckout = await createNewCheckout()
    setCheckoutInState(newCheckout)
  }

  componentDidMount() {
    // Observe viewport switching from mobile to desktop and vice versa
    const mediaQueryToMatch = `(min-width: ${breakpoints.desktop}px)`

    this.desktopMediaQuery = window.matchMedia(mediaQueryToMatch)
    this.desktopMediaQuery.addListener(this.updateViewPortState)

    this.updateViewPortState()

    // Make sure we have a Shopify checkout created for cart management.
    this.initializeCheckout()
  }

  componentWillUnmount = () => {
    this.desktopMediaQuery.removeListener(this.updateViewPortState)
  }

  updateViewPortState = (e) => {
    this.setState((state) => ({
      interface: {
        ...state.interface,
        isDesktopViewport: this.desktopMediaQuery.matches,
      },
    }))
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location !== this.props.location &&
      this.state.interface.searchStatus === 'open'
    ) {
      this.state.interface.toggleSearch()
    }
    if (
      prevProps.location !== this.props.location &&
      this.state.interface.sideMenuStatus === 'open'
    ) {
      this.state.interface.toggleSideMenu()
    }
  }
  render() {
    const {
      location,
      children,
      contentfulNotification,
      contentfulFooterLinks,
    } = this.props

    let isHomePage = location && location.pathname === '/'
    let isProductPage =
      location && location.pathname.indexOf('/products/') !== -1

    let paddingTop = 0
    if (!isHomePage) paddingTop += dimensions.headerHeight
    if (contentfulNotification && contentfulNotification.isVisible) {
      paddingTop += dimensions.notificationHeight
    }

    return (
      <>
        <StoreContext.Provider value={this.state.store}>
          <InterfaceContext.Provider value={this.state.interface}>
            <InterfaceContext.Consumer>
              {({
                isDesktopViewport,
                productImageFeatured,
                featureProductImage,
                cartStatus,
                toggleCart,
                searchStatus,
                toggleSearch,
                toggleSideMenu,
                sideMenuStatus,
              }) => (
                <>
                  <div className="flex-parent flex-parent--column flex-parent--center-cross">
                    <div className="layout">
                      <Header
                        location={location}
                        cartToggle={
                          <CartToggle status={cartStatus} toggle={toggleCart} />
                        }
                        toggleSearch={toggleSearch}
                        toggleSideMenu={toggleSideMenu}
                        notification={contentfulNotification}
                        isTransparent={isHomePage || isProductPage}
                        sideMenuStatus={sideMenuStatus}
                        searchStatus={searchStatus}
                      ></Header>
                      <SideMenu
                        status={sideMenuStatus}
                        toggle={toggleSideMenu}
                        links={[
                          { to: '/about', text: 'Despre noi' },
                          { to: '/faq', text: 'Ajutor/FAQ' },
                          {
                            to: '/privacy-policy',
                            text: 'Politica de confidentialitate',
                          },
                          {
                            to: '/terms-conditions',
                            text: 'Termeni si conditii',
                          },
                        ]}
                      />
                      <Search status={searchStatus} toggle={toggleSearch} />
                      <Cart
                        isDesktopViewport={isDesktopViewport}
                        status={cartStatus}
                        toggle={toggleCart}
                      />

                      <div
                        className="content-wrapper"
                        style={{
                          paddingTop: `${paddingTop}px`,
                        }}
                      >
                        {children}
                      </div>

                      <Footer
                        data={{
                          ...contentfulFooterLinks,
                          rightLinks: [
                            { to: '/about', text: 'Despre noi' },
                            { to: '/faq', text: 'Ajutor/FAQ' },
                            {
                              to: '/privacy-policy',
                              text: 'Politica confidentialitate',
                            },
                            {
                              to: '/terms-conditions',
                              text: 'Termeni si conditii',
                            },
                          ],
                        }}
                      />
                    </div>

                    {typeof window !== 'undefined' &&
                      !localStorage.getItem('COOKIE_POLICY_ACCEPTED') && (
                        <Cookie
                          onAccept={() => {
                            localStorage.setItem('COOKIE_POLICY_ACCEPTED', true)
                          }}
                        />
                      )}

                    <style jsx>{`
                      .layout {
                        max-width: 1440px;
                        width: 100%;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                      }
                      .content-wrapper {
                      }
                    `}</style>
                  </div>
                </>
              )}
            </InterfaceContext.Consumer>
          </InterfaceContext.Provider>
        </StoreContext.Provider>
      </>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        contentfulNotification {
          content {
            json
          }
          isVisible
          backgroundColor
        }
        contentfulFooterLinks {
          socialLinks {
            id
            text
            href
            image {
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return <Layout {...data} {...props} />
    }}
  />
)
