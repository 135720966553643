import React, { Component } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import PropTypes from 'prop-types'

import {
  MdClose,
  MdShoppingSearch,
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md'

import StoreContext from '../../contexts/StoreContext'

import { Button, PrimaryButton } from '../shared/Buttons'
import SearchListing from './SearchListing'
import { breakpoints, colors, spacing, dimensions } from '../../utils/styles'

const SearchRoot = styled(`div`)`
  background: ${colors.lightest};
  bottom: 0;
  position: fixed;
  left: 0;
  top: 0px;
  transition: all 0.25s;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  padding: ${spacing['2xl']}px;
  overflow: auto;

  &.open {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    padding: ${spacing.lg}px;
  }
`

const Heading = styled(`header`)`
  align-items: center;
  display: flex;
  height: ${dimensions.headerHeight};
  justify-content: flex-start;
`

const CloseButton = styled(`div`)`
  margin-right: ${spacing.md}px;
  margin-top: ${spacing.md}px;
  margin-left: auto;
  cursor: pointer;

  svg {
    height: 40px;
    width: 40px;
  }
`

class Search extends Component {
  state = {
    className: 'closed',
    isLoading: false,
    searchInput: null,
  }

  componentDidUpdate(prevProps) {
    const componentStatusChanged = prevProps.status !== this.props.status

    if (componentStatusChanged) {
      this.setState({
        className: this.props.status,
      })
    }
  }

  render() {
    const { status, toggle } = this.props
    const { className, searchInput } = this.state

    return (
      <SearchRoot className={`${className}`}>
        <Heading>
          <CloseButton onClick={toggle}>
            <MdClose />
          </CloseButton>
        </Heading>
        <div className="searchContent">
          <input
            className="searchInput"
            placeholder="Cauta..."
            name={'searchInput'}
            onChange={event => {
              this.setState({ [event.target.name]: event.target.value })
            }}
          />

          <div className="searchResults">
            <SearchListing searchInput={searchInput} />
          </div>
        </div>

        <style jsx>{`
          .searchContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: ${2 * spacing.xl}px;
          }
          .searchResults {
            margin-top: ${spacing.xl}px;
            margin-bottom: ${2 * spacing.xl}px;
          }
          .searchInput {
            display: block;
            background-color: ${colors.lightest};
            border: none;
            outline: none;
            border-bottom: 1px solid ${colors.lightGrey};
            color: ${colors.darkest};

            font-size: 1.4rem;
            padding: ${spacing.sm}px ${spacing.md}px;
            width: 100%;
          }
          .searchInput:focus {
            outline: 0;
            transition: box-shadow 0.15s ease-in-out;
          }
          @media (min-width: ${breakpoints.desktop}px) {
            .searchContent {
              padding-left: ${4 * spacing.xl}px;
              padding-right: ${4 * spacing.xl}px;
            }
          }
        `}</style>
      </SearchRoot>
    )
  }
}

Search.propTypes = {
  status: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default Search
