import React, { Component } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { MdShoppingCart, MdArrowBack, MdArrowForward } from 'react-icons/md'

import StoreContext from '../../contexts/StoreContext'
import CartList from './CartList'
import CartIndicator from './CartIndicator'
import EmptyCart from './EmptyCart'
import ShippingInfo from './ShippingInfo'
import Checkbox from '../ProductListing/Checkbox'
import Link from '../Link'

import { Button, PrimaryButton } from '../shared/Buttons'
import { PrimaryAnchor } from '../shared/Anchors'
import CloseButton from '../shared/CloseButton'

import { breakpoints, colors, spacing, dimensions } from '../../utils/styles'

const CartRoot = styled(`div`)`
  background: ${colors.lightest};
  bottom: 0;
  position: fixed;
  right: 0;
  top: -1px;
  transform: translateX(100%);
  transition: transform 0.75s;
  width: 100%;
  height: 100vh;
  will-change: transform;
  z-index: 1000;
  border-left: 1px solid #d3d3d3;
  box-shadow: 0 0 2px #d3d3d3;

  &.open {
    transform: translateX(0%);
  }

  &.closed {
    transform: translateX(100%);
  }

  ::after {
    background-color: ${colors.lightest};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 250ms;
  }

  &.loading {
    ::after {
      opacity: 0.9;
      pointer-events: all;
    }
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: ${dimensions.cartWidthDesktop};
  }
`

const Heading = styled(`header`)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${spacing.lg}px;
`

const Title = styled(`h3`)`
  flex-grow: 1;
  margin: 0;
  margin-left: 0px;
  position: relative;
`

const Content = styled(`div`)`
  bottom: 0;
  overflow-y: auto;
  padding: ${spacing.lg}px;
  padding-top: 0px;
  width: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.textMild};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.text};
    }
    ::-webkit-scrollbar-track {
      background: ${colors.lightGrey};
    }
  }
`

const Costs = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.sm}px;
`

const Cost = styled(`div`)`
  display: flex;
  padding: 0 ${spacing.xs}px ${spacing['2xs']}px;

  :last-child {
    padding-bottom: 0;
  }

  span {
    color: ${colors.textMild};
    flex-basis: 60%;
    font-size: 0.9rem;
    text-align: right;
  }

  strong {
    color: ${colors.darkest};
    flex-basis: 40%;
    text-align: right;
  }
`

const Total = styled(Cost)`
  border-top: 1px solid ${colors.lightGrey};
  color: ${colors.brandDark};
  margin-top: ${spacing.xs}px;
  padding-top: ${spacing.sm}px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  strong,
  span {
    color: inherit;
  }
`

const iconEntry = keyframes`
  0%, 50% {
    transform: scale(0)
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const numberEntry = keyframes`
  0%{
    transform: scale(0)
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.6);
  }
`

const CheckOut = styled(PrimaryButton)`
  font-size: 1.25rem;
  margin: ${spacing.lg}px 0 ${spacing.md}px;
  width: 100%;
`

const BackLink = styled(Button)`
  font-size: 1.25rem;
  margin-bottom: ${spacing.sm}px;
  width: 100%;
`

class Cart extends Component {
  state = {
    className: 'closed',
    isLoading: false,
    agreedTerms: false,
  }

  componentDidUpdate(prevProps) {
    const componentStatusChanged = prevProps.status !== this.props.status

    if (componentStatusChanged) {
      this.setState({
        className: this.props.status,
      })
    }
  }

  render() {
    const { status, toggle } = this.props
    const { className, agreedTerms } = this.state

    return (
      <StoreContext.Consumer>
        {({ client, checkout, removeLineItem, updateLineItem, adding }) => {
          const setCartLoading = (bool) => this.setState({ isLoading: bool })

          const handleRemove = (itemID) => async (event) => {
            event.preventDefault()
            await removeLineItem(client, checkout.id, itemID)
            setCartLoading(false)
          }

          const handleQuantityChange = (lineItemID) => async (quantity) => {
            if (!quantity) {
              return
            }
            await updateLineItem(client, checkout.id, lineItemID, quantity)
            setCartLoading(false)
          }

          const itemsInCart = checkout.lineItems.reduce(
            (total, item) => total + item.quantity,
            0
          )

          return (
            <CartRoot
              className={`${className} ${
                this.state.isLoading ? 'loading' : ''
              }`}
            >
              <div style={{ height: '100%', overflow: 'auto' }}>
                <Heading>
                  <CartIndicator itemsInCart={itemsInCart} adding={adding} />
                  <Title>Cosul tau ({itemsInCart})</Title>
                  <CloseButton onClick={toggle}></CloseButton>
                </Heading>
                {checkout.lineItems.length > 0 ? (
                  <Content>
                    <CartList
                      items={checkout.lineItems}
                      handleRemove={handleRemove}
                      updateQuantity={handleQuantityChange}
                      setCartLoading={setCartLoading}
                      isCartLoading={this.state.isLoading}
                    />

                    <Costs>
                      <Cost>
                        <span>Subtotal:</span>{' '}
                        <strong>
                          {checkout.subtotalPrice} {checkout.currencyCode}
                        </strong>
                      </Cost>
                      <Cost>
                        <span>Taxe:</span> <strong>{checkout.totalTax}</strong>
                      </Cost>
                      <Cost>
                        <span>Livrare:</span>{' '}
                        <strong>Calculat la pasul urmator</strong>
                      </Cost>
                      <Total>
                        <span>Pret total:</span>
                        <strong>
                          {checkout.totalPrice} {checkout.currencyCode}
                        </strong>
                      </Total>
                    </Costs>

                    <Checkbox
                      checked={agreedTerms}
                      style={{
                        marginTop: `${spacing.md}px`,
                        marginBottom: `${spacing.md}px`,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          this.setState({
                            agreedTerms: event.target.checked,
                          })
                        },
                      }}
                    >
                      <span>
                        Sunt de acord cu{' '}
                        <PrimaryAnchor
                          href="/terms-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Termeni si conditii
                        </PrimaryAnchor>
                      </span>
                    </Checkbox>

                    <CheckOut
                      href={checkout.webUrl}
                      className={classNames({ isDisabled: !agreedTerms })}
                    >
                      Check out <MdArrowForward />
                    </CheckOut>
                    <BackLink onClick={toggle}>
                      <MdArrowBack />
                      Inapoi la cumparaturi
                    </BackLink>

                    {/* <ShippingInfo /> */}
                  </Content>
                ) : (
                  <EmptyCart />
                )}
              </div>
            </CartRoot>
          )
        }}
      </StoreContext.Consumer>
    )
  }
}

Cart.propTypes = {
  status: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isDesktopViewport: PropTypes.bool,
}

export default Cart
