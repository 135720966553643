// import './src/styles/normalize.css'
import './src/styles/global.scss'
import './src/styles/layout.css'

import LogRocket from 'logrocket'

const enableLogRocket = false

export const onClientEntry = () => {
  if (enableLogRocket) {
    LogRocket.init('e0ureo/leafro')
  }
}

export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   `leaf.ro has been updated. ` + `Reload to display the latest version?`
  // )

  // if (answer === true) {
  window.location.reload()
  // }
}

// gatsby-browser.js
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
  ...rest
}) => {
  if (
    prevRouterProps &&
    routerProps &&
    prevRouterProps.location.pathname === routerProps.location.pathname
  ) {
    return false
  }
  return true
}
