import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { PrimaryButton } from './shared/Buttons'
import { PrimaryAnchor } from './shared/Anchors'

import { colors } from '../utils/styles'

const Cookie = ({ onAccept }) => {
  const [show, setShow] = useState(true)

  if (!show) return null
  return (
    <div className="cookie flex-parent flex-parent--row flex-parent--center-cross">
      <div className="flex-parent flex-parent--column">
        {/* <h6>Pagina nostra foloseste Cookies</h6> */}
        <div>
          <p className="">
            Acest website foloseste urmatoarele tipuri de Cookies: strict
            necesare, functionale, cookie-uri pentru performanta si marketing.
            Folosind acest website esti de acord cu{' '}
            <PrimaryAnchor href="/terms-conditions" target="_blank">
              Termeni si conditii
            </PrimaryAnchor>
          </p>
        </div>
      </div>
      <div className="flex-parent flex-parent--column right">
        <PrimaryButton
          onClick={() => {
            setShow(false)
            onAccept()
          }}
          style={{ width: '100%' }}
        >
          Accepta
        </PrimaryButton>
      </div>

      <style jsx>{`
        .cookie {
          position: fixed;
          z-index: 9999;

          left: 0;
          right: 0;
          bottom: 24px;

          max-width: 1140px;
          margin: 0 auto;

          background: white;
          border-radius: 10px;
          padding: 24px;

          box-shadow: 0 4px 8px rgba(21, 24, 31, 0.02),
            0 8px 14px rgba(21, 24, 31, 0.04),
            0 12px 32px rgba(21, 24, 31, 0.05);
          border: 1px solid ${colors.ligherGrey};
        }
        .right {
          width: 20%;
          margin-left: 16px;
        }
        @media only screen and (max-width: 1440px) {
          .cookie {
            text-align: center;
            bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            flex-direction: column;
          }
          .right {
            width: 100%;
            margin-left: unset;
            margin-top: 16px;
          }
        }
      `}</style>
    </div>
  )
}

Cookie.propTypes = {
  onAccept: PropTypes.func,
}

export default Cookie
