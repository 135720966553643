import React, { Component } from 'react'
import classNames from 'classnames'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MdSearch, MdMenu, MdClose } from 'react-icons/md'

import Link from '../../Link'
import { Anchor } from '../Anchors'
import Notification from '../../Notification'

import { colors, breakpoints, dimensions, spacing } from '../../../utils/styles'

const Bold = ({ children }) => <span className="bold">{children}</span>
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
}

const delta = 5

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      didScroll: false,
      lastScrollTop: 0,
      showNotification: true,
      showHeader: true,
    }
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)

    this.hasScrollTimer = setInterval(() => {
      // console.log('hasScrollTimer');
      const { didScroll } = this.state
      if (didScroll) {
        this.hasScrolled()
        this.setState({
          didScroll: false,
        })
      }
    }, 250)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    clearInterval(this.hasScrollTimer)
  }

  handleScroll = () => {
    this.setState({
      didScroll: true,
    })
  }

  hasScrolled = () => {
    const { lastScrollTop } = this.state

    const st = document.body.scrollTop || document.documentElement.scrollTop
    const windowHeight = document.documentElement.scrollHeight

    // // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.

    let showNotification = false
    let showHeader = true

    if (
      lastScrollTop - st < 0 &&
      st > dimensions.headerHeight + dimensions.notificationHeight
    ) {
      // Scroll Down
      showHeader = false
    } else {
      // Scroll Up
      if (lastScrollTop - st >= 0 && st > dimensions.headerHeight) {
        showHeader = true
      }
    }

    if (this.props.notification && this.props.notification.isVisible) {
      if (st > dimensions.notificationHeight) {
        // Scroll Down
        showNotification = false
      } else {
        // Scroll Up
        if (st <= dimensions.notificationHeight) {
          showNotification = true
        }
      }
    }

    // console.log(
    //   'scrolled more than delta: ',
    //   lastScrollTop - st,
    //   ' st: ',
    //   st,
    //   ' lastScrollTop: ',
    //   lastScrollTop
    // )
    // console.log({ showHeader, showNotification })

    this.setState({
      lastScrollTop: st,
      showHeader,
      showNotification,
    })
  }

  render() {
    const {
      location,
      cartToggle,
      toggleSearch,
      toggleSideMenu,
      notification,
      isTransparent,
      sideMenuStatus,
      searchStatus,
    } = this.props

    const { showHeader, showNotification } = this.state

    let shouldShowNotification =
      showNotification && notification && notification.isVisible
    let top = 0
    let contentTop = 0

    if (!showHeader) {
      contentTop -= dimensions.headerHeight

      if (!shouldShowNotification) {
        contentTop -= dimensions.notificationHeight
      }
    } else {
      contentTop = dimensions.notificationHeight
      if (!shouldShowNotification) {
        contentTop = 0
      }
    }

    if (!shouldShowNotification) {
      top -= dimensions.notificationHeight
    }

    return (
      <header
        className={classNames('header flex-parent flex-parent--column ')}
        style={{ top: top }}
        ref={this.headerRef}
      >
        {notification && notification.isVisible === true && (
          <div
            className={classNames('notification', {
              invisible: searchStatus === 'open',
            })}
          >
            <Notification color={notification.backgroundColor}>
              {documentToReactComponents(notification.content.json, options)}
            </Notification>
          </div>
        )}
        <div
          style={{ top: contentTop }}
          className={classNames(
            'header-content flex-parent flex-parent--column sectionContainer',
            {
              opaque: !isTransparent,
              invisible: searchStatus === 'open',
            }
          )}
        >
          <ul className="flex-parent flex-parent--row secondRow">
            <li className="mobile">
              <button
                aria-label="Side menu icon"
                className="reset"
                onClick={toggleSideMenu}
              >
                {sideMenuStatus === 'open' ? (
                  <MdClose size={30} />
                ) : (
                  <MdMenu size={30} />
                )}
              </button>
            </li>
            <li className="desktop">
              <Anchor to={'/collections/plants'}>Plante</Anchor>
            </li>
            <li className="desktop">
              <Anchor to={'/collections/pots'}>Ghivece</Anchor>
            </li>
            {/* <li className="desktop">
              <Anchor to={'/collections/accesories'}>Accesorii</Anchor>
            </li> */}

            {sideMenuStatus !== 'open' && (
              <li className="right">
                <ul className="flex-parent flex-parent--row secondRow">
                  <li>
                    <button
                      aria-label="Search icon"
                      className="reset"
                      onClick={toggleSearch}
                    >
                      <MdSearch size={30} />
                    </button>
                  </li>
                  <li>{cartToggle}</li>
                </ul>
              </li>
            )}
          </ul>

          <div className="logo">
            <Link to={'/'}>
              <h5 style={{ fontSize: '24px', fontWeight: '600' }}>LEAF.ro</h5>
            </Link>
          </div>
        </div>
        <style jsx>{`
          .header {
            max-width: 1440px;
            width: 100%;
            z-index: 1000;
            position: fixed;
            top: 0;
            transition: all 0.2s ease-in-out;
          }
          .invisible {
            display: none !important;
          }
          .header-content {
            max-width: 1440px;
            height: ${dimensions.headerHeight}px;
            width: 100%;

            z-index: 99;
            position: fixed;
            top: 0;

            display: flex;
            justify-content: center;
            transition: all 0.2s ease-in-out;
          }
          .logo {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
          }
          .opaque {
            background-color: white;
          }

          .desktop {
            display: flex !important;
          }
          .mobile {
            display: none !important;
          }
          @media (max-width: ${breakpoints.desktop}px) {
            .header-content {
              background-color: white;
            }
            .desktop {
              display: none !important;
            }
            .mobile {
              display: flex !important;
            }
          }

          .header-content:hover {
            background-color: white;
          }
          .notification {
            height: ${dimensions.notificationHeight}px;
            width: 100%;
            position: absolute;
            left: 0;
          }

          .menu {
            color: black;
          }
          .separator {
            width: 100%;
            height: 1px;
            background-color: ${colors.darkest};
            margin-top: ${spacing.xs}px;
            margin-bottom: ${spacing.xs}px;
          }
          .firstRow {
            margin-bottom: ${spacing.md}px;
          }
          .secondRow {
            list-style-type: none;
            padding: 0;
            margin: 0;
            height: 100%;
          }
          .secondRow li {
            margin-right: ${spacing.md}px;
            margin-bottom: 0px;
            display: flex;
            justify-content: center;
          }
          .secondRow > li:last-of-type {
            margin-right: 0px;
          }
          .secondRow > * {
            display: flex;
            align-items: center;
          }
          .right {
            margin-left: auto;
            margin-right: 0;
          }
          .middle {
            margin-left: auto;
            margin-right: auto;
          }
        `}</style>
      </header>
    )
  }
}

export default Header
