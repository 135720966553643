import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import IconImage from '../IconImage'

import { breakpoints, spacing, colors } from '../../utils/styles'

const Checkbox = ({
  title,
  image,
  checked,
  disabled,
  inputProps,
  children,
  ...otherProps
}) => {
  return (
    <label
      className={disabled ? 'container disabled' : 'container'}
      {...otherProps}
    >
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        {...inputProps}
      />
      <span className="checkmark"></span>
      {image && image.length > 0 && (
        <IconImage
          src={`/${image}`}
          style={{
            width: '30px',
            height: '30px',
            marginRight: `${spacing.xs}px`,
          }}
        />
      )}
      {title && <p>{title}</p>}
      {children && children}
      <style jsx>{`
        .disabled > * {
          opacity: 0.5;
        }
        .container {
          display: flex;
          align-items: center;
          page-break-inside: avoid;
          margin-left: 1px;
        }
        .container > input {
          margin-right: ${spacing.sm}px;

          -webkit-appearance: none;
          width: 14px;
          height: 14px;
          padding: 0;
          border: 0;
          outline: 1px solid ${colors.lightGrey};
          position: relative;
          min-width: 13px;
        }
        .container > input:after {
          content: ' ';
          background-image: url('/images/checkmark.svg');
          background-size: 10px 10px;
          height: 10px;
          width: 10px;
          position: absolute;
          visibility: hidden;
          opacity: 0;
          left: 2px;
          top: 2px;
          right: 0;
        }
        .container > input:checked:after {
          visibility: visible;
          opacity: 1;
        }
      `}</style>
    </label>
  )
}

export default Checkbox
