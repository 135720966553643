import React from 'react'
import Link from '../Link'
import styled from '@emotion/styled'

import { MdClose } from 'react-icons/md'

const CloseDiv = styled(`div`)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  svg {
    height: 40px;
    width: 40px;
  }
`

const CloseButton = ({ onClick, color = 'black', size = 40, ...rest }) => {
  return (
    <div
      onClick={onClick}
      style={{ color: color }}
      {...rest}
      className="closeButton"
    >
      <MdClose size={size} />
      <style jsx>{`
        .closeButton {
          cursor: pointer;
          display: flex;
          justify-content: center;
        }
      `}</style>
    </div>
  )
}

export default CloseButton
