import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export default class IconImage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allFile {
              edges {
                node {
                  childImageSharp {
                    fixed(width: 200, height: 200) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          // console.log(data);
          const { src, ...other } = this.props

          const fixed = data.allFile.edges.find(element => {
            // Match string after final slash
            if (
              !element.node ||
              !element.node.childImageSharp ||
              !element.node.childImageSharp.fixed ||
              !element.node.childImageSharp.fixed.src
            )
              return null
            // console.log(element.node.childImageSharp.fixed.src.split('/').pop());
            return (
              element.node.childImageSharp.fixed.src.split('/').pop() ===
              src.split('/').pop()
            )
          })
          if (!fixed) return null
          return <Img fixed={fixed.node.childImageSharp.fixed} {...other} />
        }}
      />
    )
  }
}
