import React from 'react'
import styled from '@emotion/styled'
import { MdClose } from 'react-icons/md'
import { keyframes } from '@emotion/core'

import { colors, spacing, dimensions, breakpoints } from '../../utils/styles'
import { Button } from '../shared/Buttons'
import StoreContext from '../../contexts/StoreContext'
import { Anchor } from '../shared/Anchors'

const ItemsNumber = styled(`span`)`
  align-items: center;
  background: ${colors.lemon};
  border-radius: 50%;
  color: ${colors.darkest};
  display: flex;
  font-size: 1.3rem;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  width: 36px;
`

const CartList = ({ status, toggle }) => (
  <>
    <StoreContext.Consumer>
      {({ checkout }) => {
        const setCartLoading = (bool) => this.setState({ isLoading: bool })

        const itemsInCart = checkout.lineItems.reduce(
          (total, item) => total + item.quantity,
          0
        )

        return (
          <Anchor
            aria-label={`Cos cumparaturi cu ${itemsInCart} produse`}
            onClick={toggle}
          >
            <p>Cos {itemsInCart > 0 && ` (${itemsInCart})`}</p>
          </Anchor>
        )
      }}
    </StoreContext.Consumer>
  </>
)

export default CartList
