import { keyframes } from '@emotion/core'

export const spacing = {
  '3xs': 2,
  '2xs': 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  '2xl': 40,
  '3xl': 48,
}

export const breakpoints = {
  mobile: 400,
  phablet: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1300,
}

export const radius = {
  default: 2,
  large: 4,
}

export const colors = {
  brandDark: '#00A482',
  brand: '#00B992',
  lightest: '#ffffff',
  darkest: '#222222',
  text: '#333333',
  textMild: '#555555',
  textLighter: '#aaaaaa',
  lightGrey: '#D3D3D3',
  ligherGrey: '#F0F0F0',
  lemon: '#ffdf37',
  yellow: '#ffee75',
  blue: '#B0DFE5',
  red: '#ff5252',
}

const simpleEntry = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
`

const deadSimpleEntry = keyframes`
  from {
    opacity: .25;
  }
`

export const animations = {
  simpleEntry: `${simpleEntry} .75s ease forwards`,
  deadSimpleEntry: `${deadSimpleEntry} .5s ease forwards`,
}

export const dimensions = {
  headerHeight: 55,
  notificationHeight: 44,
  variantOptionsHeight: 44,
  cartWidthDesktop: '400px',
  contributorAreaWidth: {
    closedDesktop: '60px',
    openDesktop: '340px',
    openHd: '420px',
  },
  contributorAreaBarHeight: '50px',
  pictureBrowserAction: {
    widthDesktop: '200px',
    heightMobile: '80px',
  },
}
