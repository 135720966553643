import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { colors, dimensions, radius, spacing } from '../../utils/styles'

const CartIndicatorRoot = styled(`div`)`
  background-color: ${colors.lightest};
  border-radius: ${radius.default}px;
  color: ${colors.brand};
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  left: 0;
  top: 100px;
  z-index: 999;
  padding: ${spacing.xs}px ${spacing.sm}px;
  position: absolute;
  top: calc(${dimensions.headerHeight}px + ${spacing.md}px);
  transform: translateX(calc((100% + ${spacing.md}px) * -1));
  border: 1px solid ${colors.brand};
`

class CartIndicator extends Component {
  state = {
    visible: false,
    message: '',
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adding !== this.props.adding) {
      if (this.props.adding) {
        this.setState({
          visible: true,
          message: 'actualizare cos ...',
        })
      } else {
        if (this.props.itemsInCart > prevProps.itemsInCart) {
          const num = this.props.itemsInCart - prevProps.itemsInCart
          const message =
            num > 1
              ? `${num} produse au fost adaugate in cos`
              : `${num} produs nou a fost adaugat in cos`

          this.setState({ message })

          setTimeout(() => this.setState({ visible: false, message: '' }), 3000)
        }
      }
    }
  }

  render() {
    const { visible, message } = this.state

    return <CartIndicatorRoot visible={visible}>{message}</CartIndicatorRoot>
  }
}

CartIndicator.propTypes = {
  itemsInCart: PropTypes.number.isRequired,
  adding: PropTypes.bool.isRequired,
}

export default CartIndicator
