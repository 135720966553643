import React from 'react'
import classNames from 'classnames'

import { colors, breakpoints, spacing } from '../utils/styles'

const Notification = ({ text, color, children, className, ...props }) => (
  <div
    {...props}
    className={classNames(
      'flex-parent flex-parent--column flex-parent--center-cross flex-parent--center-main',
      className,
      'notification'
    )}
    style={{ backgroundColor: color ? color : colors.brand }}
  >
    {children}
    <style jsx>
      {`
        .notification {
          padding-left: ${spacing.sm}px;
          padding-right: ${spacing.sm}px;
          padding-top: ${spacing.xs}px;
          padding-bottom: ${spacing.xs}px;

          width: 100%;
          text-align: center;
          color: ${colors.lightest};
          height: 100%;
        }
        @media (max-width: ${breakpoints.phablet}px) {
          .notification {
            font-size: 12px;
          }
        }
      `}
    </style>
  </div>
)

export default Notification
